import React from 'react';

import Button from 'antd/es/button';

import Footer from '../components/footer';
import Header from '../components/header';
import Content from '../components/content';
import SEO from '../components/seo';
import Icon from '../components/icon';
import Analytics from '../components/analytics';

import cooperateStyle from '../styles/cooperate.module.less';

export default () => {
    const checkedWayList = [
        {
            title: '发展前景无量',
            sub: 'The development prospect is boundless',
            alt: '发展前景',
            colorClass: cooperateStyle.colorWathet,
            icon: 'iconfazhanqianjingwuliang'
        },
        {
            title: '简单易用',
            sub: 'Simple and easy to use',
            alt: '简单易用',
            colorClass: cooperateStyle.colorWhite,
            icon: 'iconjiandanyiyong'
        },
        {
            title: '专业顾问',
            sub: 'Professional advisor',
            alt: '专业顾问',
            colorClass: cooperateStyle.colorBlue,
            icon: 'iconkefu'
        }
    ];

    const joinWeList = [
        {
            icon: 'iconphone1',
            alt: '电话',
            content: '400-651-3115'
        },
        {
            icon: 'iconxinfengyoujian',
            alt: '邮箱',
            content: 'marketing@kuguanwang.com'
        },
        {
            icon: 'iconziyuan',
            alt: '地址',
            content: '上海市普陀区金昌商务中心常德路1339号'
        }
    ];

    const weFlowList = [
        {
            icon: 'icontijiaoshenqing',
            title: '提交申请'
        },
        {
            icon: 'iconhezuoqiatan',
            title: '合作洽谈'
        },
        {
            icon: 'iconqianyuehezuo',
            title: '签约合作'
        },
        {
            icon: 'iconhezuopeixun',
            title: '合作培训'
        },
        {
            icon: 'iconsuccess',
            title: '合作成功'
        }
    ];

    return (
        <section>
            <SEO title="渠道合作" />
            <Header />
            <Content className={cooperateStyle.checkedWay}>
                <h2>为什么选择简库存？</h2>
            </Content>
            <div style={{ backgroundColor: 'rgba(87, 122, 173, 0.3)' }}>
                <Content className={cooperateStyle.checkedWay}>
                    <ul>
                        {checkedWayList.map((checkWay, index) => (
                            <li key={index} className={checkWay.colorClass}>
                                <Icon
                                    type={checkWay.icon}
                                    className={cooperateStyle.checkedWay__icon}
                                />
                                <p>{checkWay.title}</p>
                                <span>{checkWay.sub}</span>
                            </li>
                        ))}
                    </ul>
                </Content>
            </div>
            <Content className={cooperateStyle.weSuperiority}>
                <h2>我们的优势</h2>
                <h3>为您提供专业、简洁、高效的办公场景</h3>
                <ul>
                    <li>产品</li>
                    <li>技术</li>
                    <li>服务</li>
                    <li>资源</li>
                </ul>
            </Content>
            <div style={{ backgroundColor: 'rgba(227, 242, 255, 0.3)' }}>
                <Content className={cooperateStyle.weFlow}>
                    <h2>商务流程</h2>
                    <ul>
                        {weFlowList.map((value, index) => (
                            <section key={index}>
                                <li>
                                    <i>
                                        <Icon
                                            type={value.icon}
                                            className={cooperateStyle.weFlow__icon}
                                        />
                                    </i>
                                    <p>{value.title}</p>
                                </li>
                                {index !== weFlowList.length - 1 && (
                                    <li>
                                        <Icon
                                            type={`iconyoujiantou`}
                                            className={cooperateStyle.weFlow__arrow}
                                        />
                                    </li>
                                )}
                            </section>
                        ))}
                    </ul>
                    <Button type="primary" className={cooperateStyle.weFlow__button}>
                        Tel: 400-651-3115
                    </Button>
                </Content>
            </div>

            <Content className={cooperateStyle.joinWe}>
                <h2>加入我们</h2>
                <h3>协作共赢 资源共享 为客户创造更好的体验</h3>
                <ul>
                    {joinWeList.map((value, index) => (
                        <li key={index}>
                            <Icon type={value.icon} className={cooperateStyle.joinWe__logo}></Icon>
                            <span>{value.content}</span>
                        </li>
                    ))}
                </ul>
            </Content>
            <Analytics />
            <Footer />
        </section>
    );
};
